* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  cursor: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  background: var(--color-bg);
  font-family: var(--font-base);
  color: var(--color-text);
}

.section__padding {
  padding: 1.5rem 1rem;
}

.grid {
  display: grid;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: 1.5fr 2fr 2fr;
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: plum;
  mix-blend-mode: difference;
  z-index: 999;
  pointer-events: none;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 1rem 1rem;
  }
}

.bottom-border {
  border-bottom: 1px solid var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
