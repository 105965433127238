.dots {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#dot {
  width: 90px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #b45c2c 94%, #0000);
  background: var(--_g) 0 0, var(--_g) 100% 0, var(--_g) 100% 100%,
    var(--_g) 0 100%;
  background-size: 40% 40%;
  animation: dot 0.7s infinite;
}

@keyframes dot {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}
