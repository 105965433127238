.portfolio__bucingballs {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.portfolio__bucingballs-balls {
  width: 13em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.portfolio__bucingballs-balls div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-text);
}

.portfolio__bucingballs-balls div:nth-of-type(1) {
  transform: translateX(-5%);
  animation: left-swing 1s ease-in alternate infinite;
}

.portfolio__bucingballs-balls div:nth-of-type(3) {
  transform: translateX(-95%);
  animation: right-swing 1s ease-out alternate infinite;
}

@keyframes left-swing {
  50%,
  100% {
    transform: translateX(95%);
  }
}

@keyframes right-swing {
  50% {
    transform: translateX(-95%);
  }
  100% {
    transform: translateX(5%);
  }
}

@media screen and (max-width: 767px) {
  .portfolio__bucingballs-balls {
    width: 12em;
  }
  .portfolio__bucingballs-balls div {
    width: 33px;
    height: 33px;
  }
}

@media screen and (max-width: 320px) {
  .portfolio__bucingballs-balls {
    width: 10em;
  }
  .portfolio__bucingballs-balls div {
    width: 28px;
    height: 28px;
  }
}
