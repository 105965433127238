.portfolio__jumpingballs {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.portfolio__jumpingballs-balls {
  width: 140px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.portfolio__jumpingballs-balls div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-text);
  transform: translateY(-100%);
  animation: wave 0.9s ease-in-out alternate infinite;
}

.portfolio__jumpingballs-balls div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.portfolio__jumpingballs-balls div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes wave {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}

@media screen and (max-width: 320px) {
  .portfolio__jumpingballs-balls {
    width: 100px;
  }
  .portfolio__jumpingballs-balls div {
    width: 32px;
    height: 32px;
  }
}
